/**
 * Util function to join css classes
 * @param classes {Array<string>}
 * @returns string
 */
export default function classNames(
    ...classes: Array<string | undefined>
): string {
    return classes.filter(Boolean).join(' ') || ''
}
