// react
import { FC, memo, PropsWithChildren } from 'react'
// utils
import { classNames } from '@utils'
/* types */
import { SCROLL_MAP } from '@types'
// styles
import './index.scss'

const ScrollLayout: FC<PropsWithChildren<{
    className?: string
    classNameContent?: string
    scroll?: SCROLL_MAP
    [x: string]: any
}>> = ({ className, classNameContent, scroll = SCROLL_MAP.Y, children, ...rest }) => {
    return (
        <div className={classNames('scroll-layout', className)} {...rest}>
            <div
                id="scroll-layout"
                className={classNames(
                    'scroll-layout__content',
                    scroll || SCROLL_MAP[scroll],
                    classNameContent
                )}
            >
                {children}
            </div>
        </div>
    )
}

export default memo(ScrollLayout)
