/* Map the position parent flex direction */
export enum POSITION_PARENT_MAP {
    TOP = 'accordion-layout--top',
    RIGHT = 'accordion-layout--right',
    BOTTOM = 'accordion-layout--bottom',
    LEFT = 'accordion-layout--left',
}

/* Map modal content position */
export enum MODAL_POSITION {
    TOP = 'modal-layout--top',
    RIGHT = 'modal-layout--right',
    BOTTOM = 'modal-layout--bottom',
    LEFT = 'modal-layout--left',
    CENTER = 'modal-layout--center',
}

/* Map select content position */
export enum SELECT_POSITION {
    TOP = 'select__options--top',
    RIGHT = 'select__options--right',
    BOTTOM = 'select__options--bottom',
    LEFT = 'select__options--left',
    CENTER = 'select__options--center',
}

export enum BADGES_POSITION_MAP {
    TOP = 'badges--top',
    RIGHT = 'badges--right',
    BOTTOM = 'badges--bottom',
    LEFT = 'badges--left',
}
