export type BreakPoint =
    | ''
    | 'xs10'
    | 'xs9'
    | 'xs8'
    | 'xs7'
    | 'xs6'
    | 'xs5'
    | 'xs4'
    | 'xs3'
    | 'xs2'
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | 'xl2'
    | 'xl3'
    | 'xl4'
    | 'xl5'
    | 'xl6'
    | 'xl7'
    | 'xl8'
    | 'xl9'
    | 'xl10'

export const BreakPointValue: Record<BreakPoint, number> = {
    '': 0,
    xs10: 64,
    xs9: 128,
    xs8: 192,
    xs7: 256,
    xs6: 320,
    xs5: 384,
    xs4: 448,
    xs3: 512,
    xs2: 576,
    xs: 640,
    sm: 704,
    md: 768,
    lg: 896,
    xl: 1025,
    xl2: 1152,
    xl3: 1280,
    xl4: 1408,
    xl5: 1536,
    xl6: 1664,
    xl7: 1792,
    xl8: 1920,
    xl9: 2048,
    xl10: 2176,
}
