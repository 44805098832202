// react
import { FC, memo, PropsWithChildren } from 'react'
// utils
import classNames from '@utils/class-names.util'
// types
import { ORIENTATION_MAP } from '@types'
// styles
import './index.scss'

const BaseLayout: FC<
    PropsWithChildren<{
        className?: string
        orientation?: ORIENTATION_MAP
        [x: string]: any
    }>
> = ({ className, orientation = ORIENTATION_MAP.col, children, ...rest }) => {
    return (
        <div
            className={classNames(
                'base-layout',
                orientation || ORIENTATION_MAP[orientation],
                className
            )}
            {...rest}
        >
            {children}
        </div>
    )
}

export default memo(BaseLayout)
