import { FC, PropsWithChildren, memo } from 'react'
import { classNames } from '@utils'
import { VARIANT_BUTTON_MAP, ROLE_BUTTON_MAP, TYPE_BUTTON_MAP } from '@types'
import './index.scss'

const Button: FC<
    PropsWithChildren<{
        className?: string
        disabled?: boolean
        variant?: VARIANT_BUTTON_MAP
        role?: ROLE_BUTTON_MAP
        forms?: TYPE_BUTTON_MAP
        onClick?: () => void
        leftIcon?: any
        rightIcon?: any
        badge?: any
        [x: string]: any
    }>
> = ({
    className,
    disabled,
    variant = VARIANT_BUTTON_MAP.TEXT,
    role = ROLE_BUTTON_MAP.NONE,
    forms = TYPE_BUTTON_MAP.ROUNDED,
    onClick,
    leftIcon,
    rightIcon,
    badge,
    children,
    ...rest
}) => {
    return (
        <button
            className={classNames(
                'button',
                role || ROLE_BUTTON_MAP[role],
                variant || VARIANT_BUTTON_MAP[variant],
                forms || TYPE_BUTTON_MAP[forms],
                badge ? 'button__badge' : '',
                className
            )}
            disabled={disabled}
            onClick={onClick}
            {...rest}
        >
            {leftIcon && <i className="icon">{leftIcon}</i>}
            {children}
            {rightIcon && <i className="icon">{rightIcon}</i>}
            {badge && badge}
        </button>
    )
}

export default memo(Button)
